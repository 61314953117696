import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ActualizarInformacionFacturacionService
} from '../../../../domain/administrador/modules/informacion-facturacion/actualizar-informacion-facturacion.service';
import {ValidationService} from '../../../../infraestructure/validation/validation.service';
import {
  GetInformacionLicenciaService
} from '../../../shared/modules/get-informacion-licencia/get-informacion-licencia.service';
import {InformacionLicencia} from '../../../shared/modules/get-informacion-licencia/informacion-licencia';
import {MetodoDePagoComponent} from '../metodo-de-pago/metodo-de-pago.component';
import {
  FormularioInformacionFacturacionComponent
} from '../formulario-informacion-facturacion/formulario-informacion-facturacion.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-renovar-licencia',
  templateUrl: './modal-renovar-licencia.component.html',
  styleUrls: ['./modal-renovar-licencia.component.scss']
})
export class ModalRenovarLicenciaComponent implements OnInit {
  public primeraParte = true;
  public segundaParte = false;
  public terceraParte = false;
  @ViewChild('formularioInformacionFacturacion') public formularioInformacionFacturacion: FormularioInformacionFacturacionComponent;
  @ViewChild('metodoDePago') public metodoDePago: MetodoDePagoComponent;
  public informacionLicencia: InformacionLicencia;
  public importeTotalPagar: number;
  public numeroDeUsuariosActivos = 0;
  public importeBruto: number;
  public cuartaParte: boolean;

  constructor(private modalService: NgbActiveModal,
              private getInformacionLicenciaService: GetInformacionLicenciaService,
              private validationService: ValidationService,
              private ngxLoader: NgxUiLoaderService,
              private actualizarInformacionFacturacionService: ActualizarInformacionFacturacionService) {
  }

  public ngOnInit(): void {
    this.getInformacionLicenciaService.__invoke().subscribe(
      (informacionLicencia) => {
        this.informacionLicencia = informacionLicencia;
        this.importeBruto = (informacionLicencia.numero_usuarios_asignados * 2);
        this.importeTotalPagar = (informacionLicencia.numero_usuarios_asignados * 2.42);
      });
  }

  public dismiss() {
    this.modalService.dismiss();
  }

  public navegaFacturacion(): void {
    this.segundaParte = true;
    this.primeraParte = false;
  }

  public navegaTarjeta(): void {
    this.ngxLoader.start();
    this.actualizarInformacionFacturacionService.__invoke(this.formularioInformacionFacturacion.informacionFacturacionForm.getRawValue()).subscribe(
      () => {
        this.ngxLoader.stop();
        this.segundaParte = false;
        this.terceraParte = true;
      }, (errorResponse) => {
        this.ngxLoader.stop();
        this.validationService.validateForm(this.formularioInformacionFacturacion.informacionFacturacionForm, errorResponse.error.errors);
        this.formularioInformacionFacturacion.erroresCabecera = this.validationService.obtenerMensajesDeValidacion(errorResponse.error.errors);
      }
    );

  }

  public pagar(): void {
    this.metodoDePago.enviarPago();
  }

  public volverPrimeraParte() {
    this.segundaParte = false;
    this.primeraParte = true;
  }

  public volverPrimeraSegundaParte() {
    this.segundaParte = true;
    this.terceraParte = false;
  }

  public pagoRealizado() {
    this.terceraParte = false;
    this.cuartaParte = true;
  }

  public finalizar() {
    this.modalService.close();
  }
}
