import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingButtonDirective} from './loading-button.directive';
import {SelectOnFocusDirective} from './select-on-focus.directive';
import {DragAndDropFileDirective} from "./drag-and-drop-file.directive";
import {BotonFichajeCargandoDirective} from './boton-fichaje-cargando.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoadingButtonDirective,
    SelectOnFocusDirective,
    DragAndDropFileDirective,
    BotonFichajeCargandoDirective
  ],
  exports: [
    LoadingButtonDirective,
    SelectOnFocusDirective,
    DragAndDropFileDirective,
    BotonFichajeCargandoDirective
  ]
})
export class DirectivesModule {
}
