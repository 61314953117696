import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreviewConfiguracionNotificacionesComponent} from './preview-configuracion-notificaciones.component';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../../../infraestructure/directives/directives.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularxDatatableModule} from '@lunia/angularx-datatable';
import {NgToggleModule} from 'ng-toggle-button';


@NgModule({
  declarations: [
    PreviewConfiguracionNotificacionesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    AngularxDatatableModule,
    NgToggleModule.forRoot()
  ],
  exports: [
    PreviewConfiguracionNotificacionesComponent
  ]
})
export class PreviewConfiguracionNotificacionesModule {
}
