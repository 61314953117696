<div class="sticky-top bg-white overflow-x-hidden">
  <div class="modal-header preview preview-header-widget">
    <h3 class="modal-title ps-3">{{ 'Notificaciones' | translate }}</h3>
    <button (click)="cierraPanel('notificaciones')" aria-label="Close" class="btn-close btn-close-white me-2" type="button">
      </button>
  </div>
  <div class="modal-body mt-3">
    <div class="row px-3" [formGroup]="configuracionNotificacionesForm">
      <ng-container formArrayName="configuracion_notificaciones">
        <ng-container
          *ngFor="let configuracionNotificacion of getConfiguracionNotificacionesFormArray().controls let i = index;">
          <div [formGroupName]="i"
               class="col-lg-12 px-2 my-1">
            <div class="card card-widget-configuracion rounded ">
              <div class="card-header-sm card-header-widget ps-4">
                {{ configuracionNotificacion.get('descripcion').value }}
              </div>
              <ng-container formArrayName="formatos_notificacion">
                <div class="card-body px-3 py-2">
                  <ng-container
                    *ngFor="let formatoNotificacion of getFormatosNotificacionFormArray(configuracionNotificacion).controls; let j = index;">
                    <div [formGroupName]="j" class="d-flex justify-content-between"
                         *ngIf="formatoNotificacion.get('visible').value">
                      <label class="col-form-label mt-1">{{ formatoNotificacion.get('descripcion').value }}</label>
                      <ng-toggle
                        class="mt-2"
                        width="40"
                        [color]='{ "unchecked": "#BFCBD9","checked": "#570DBF","disabled": "#DBDBDB"}'
                        height="20"
                        (change)="changeConfiguracionNotificacion(configuracionNotificacion.get('id').value, formatoNotificacion.get('id').value, formatoNotificacion.get('activo').value)"
                        formControlName="activo">
                      </ng-toggle>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
