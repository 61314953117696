import { Injectable } from '@angular/core';
import {ActiveToast} from 'ngx-toastr/toastr/toastr.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GestionToastrService {

  private toasters: ActiveToast<any>[] = [] as ActiveToast<any>[];

  constructor(private toastrService: ToastrService) { }

  public addToastr(toaster: ActiveToast<any>): void {
    this.toasters.push(toaster);
  }

  public removeToaster() {
    this.toasters.forEach((toastr: ActiveToast<any>) => {
      this.toastrService.remove(toastr.toastId);
    });
    this.toasters = [];
  }
}
