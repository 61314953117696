import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PreviewSoporteComponent} from "./preview-soporte.component";
import {NgToggleModule} from "ng-toggle-button";
import {SoporteModule} from "../soporte/soporte.module";


@NgModule({
  declarations: [
    PreviewSoporteComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgToggleModule,
    SoporteModule,
  ],
  exports: [
    PreviewSoporteComponent
  ]
})
export class PreviewSoporteModule {
}
