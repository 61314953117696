import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../app-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfiguracionNotificacion} from './configuracion-notificacion.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionUsuarioService {
  public configuracionUsuarioUrl = '';
  private configuracionNotificacionesUsuarioUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.configuracionUsuarioUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-trabajador/configuracion-usuario';
    this.configuracionNotificacionesUsuarioUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-trabajador/configuracion-notificaciones';
  }

  public vincularCuentaSocial(userData: any): Observable<any> {
    return this.httpClient.put<Observable<any>>(this.configuracionUsuarioUrl + '/vincular-cuenta', userData);
  }

  public getSocialIdUsuario(): Observable<any> {
    return this.httpClient.get<any>(this.configuracionUsuarioUrl + '/cuenta-vinculada');
  }

  public desvincularCuentaUsuario() {
    return this.httpClient.put(this.configuracionUsuarioUrl + '/desvincular-cuenta', {});
  }

  public getConfiguracionNotificacionesTrabajador(): Observable<ConfiguracionNotificacion[]> {
    return this.httpClient.get(this.configuracionNotificacionesUsuarioUrl)
      .pipe(map(configuracionNotificaciones => configuracionNotificaciones as ConfiguracionNotificacion[]));
  }

  public actualizarConfiguracionNotificacionTrabajador(data: any): Observable<ConfiguracionNotificacion[]> {
    return this.httpClient.post(this.configuracionNotificacionesUsuarioUrl, data)
      .pipe(map(configuracionNotificaciones => configuracionNotificaciones as ConfiguracionNotificacion[]));
  }
}
