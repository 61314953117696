<div class="modal-header preview preview-header-widget">
  <h3 class="modal-title ps-3">
    {{ 'Contactar con soporte' | translate }}</h3>
  <button (click)="dismiss()" aria-label="Close" class="btn-close btn-close-white me-2" type="button"></button>
</div>
<div class="modal-body">
  <form [formGroup]="soporteForm">
    <div class="row mt-2 p-3">
      <div class="col-12">
        <div class="row">
          <div class="col-12 mb-3">
             <label class="h3" for="asunto">{{'Asunto' | translate}}</label>
            <input [ngClass]="{'is-invalid': soporteForm.get('asunto').errors}" class="input-login"
                   formControlName="asunto" id="asunto" type="text">
            <small *ngIf="soporteForm.get('asunto').errors"
                   class="text-danger">{{soporteForm.get('asunto').errors[0]}}
            </small>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 mb-2">
             <label class="h3" for="descripcion">{{'Descripción' | translate}}</label>
            <textarea [ngClass]="{'is-invalid': soporteForm.get('descripcion').errors}" class="input-login"
                      formControlName="descripcion"
                      id="descripcion" rows="6" type="text">
            </textarea>
            <small *ngIf="soporteForm.get('descripcion').errors"
                   class="text-danger">{{soporteForm.get('descripcion').errors[0]}}
            </small>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="px-3">
    <button (click)="enviarTicketSoporte()" class="boton-modal-primary mt-2">
      {{ 'Enviar' | translate }}
    </button>
  </div>
</div>


