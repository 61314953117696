import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinalizarDescansoService {
  private descansoUrl: string;

  constructor(private httpClient: HttpClient) {
    this.descansoUrl = environment.apiUrl + '/backoffice-trabajador/descanso/finalizar';
  }

  public __invoke(data: any, token): Observable<boolean> {
    return this.httpClient.post(this.descansoUrl, data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    }).pipe(map(descanso => descanso as boolean));
  }
}
