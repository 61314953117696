import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MetodoDePagoComponent} from "./metodo-de-pago.component";


@NgModule({
  declarations: [
    MetodoDePagoComponent,
  ],
  exports: [
    MetodoDePagoComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MetodoDePagoModule {
}
