<div class="text-center">
  <div *ngIf="!tieneToken()">
    <a (click)="loginControlPresencia()" class="btn btn-sm btn-link" style="margin-top: 10px">Login Control
      Presencia</a>
  </div>
  <ng-container *ngIf="tieneToken()">
    <div class="d-flex justify-content-between contenedor-widget-fichaje">
      <div class=" d-flex align-items-center duracion-jornada-widget-fichaje">
        <h3 class="text-center" style="margin-bottom: 0">{{ duracionJornada }}</h3>
      </div>
      <div class="botones-widget-fichaje">
        <button (click)="compruebaEstadoFichaje()" [disabled]="creandoRegistro"
                *ngIf="!ultimoMovimiento.descanso_activo"
                class="btn btn-xs btn-sm btn-primary w-100">
          {{ultimoMovimiento.tipo === 2 ? 'REGISTRAR ENTRADA' : 'REGISTRAR SALIDA'}}
        </button>
        <button class="btn btn-descanso btn-xs btn-sm w-100 my-2" (click)="accionDescanso()"
                [disabled]="creandoRegistro"
                *ngIf="ultimoMovimiento.tipo === 1">
          {{ultimoMovimiento.descanso_activo ? 'REANUDAR JORNADA' : 'INICIAR DESCANSO'}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
