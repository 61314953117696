import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {zip} from "rxjs";
import {
  GetInformacionLicenciaService
} from "../../../shared/modules/get-informacion-licencia/get-informacion-licencia.service";
import {
  ObtenerTokenClienteBraintreeService
} from "../../../../domain/administrador/modules/informacion-facturacion/obtener-token-cliente-braintree.service";
import {environment} from "../../../../../environments/environment";
import * as dropin from 'braintree-web-drop-in';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {
  ActualizarBraintreeTokenService
} from "../../../../domain/administrador/modules/informacion-licencia/actualizar-braintree-token.service";
import {ToastrService} from "ngx-toastr";
import {InformacionLicencia} from "../../../shared/modules/get-informacion-licencia/informacion-licencia";

@Component({
  selector: 'app-metodo-de-pago',
  templateUrl: './metodo-de-pago.component.html',
  styleUrls: ['./metodo-de-pago.component.scss']
})
export class MetodoDePagoComponent implements OnInit {
  public informacionLicencia: InformacionLicencia;
  @Input('mostrarBotonActualizar') public mostrarBotonActualizar: boolean = true;
  @ViewChild('submitButton') public submitButton: ElementRef<HTMLElement>;
  @Output() licenciaActualizada = new EventEmitter<number>();

  constructor(
    private getInformacionLicenciaService: GetInformacionLicenciaService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService,
    private actualizarBraintreeTokenService: ActualizarBraintreeTokenService,
    private obtenerTokenClienteBraintreeService: ObtenerTokenClienteBraintreeService) {
  }

  ngOnInit(): void {
    this.obtenerInformacionMetodoDePago();
  }

  public createBraintreeUI(token?: string): void {
    const submitButton = document.querySelector('#submit-button');
    const braintreeData = {
      authorization: token ?? environment.braintreeToken,
      vaultManager: !!token,
      selector: '#dropin-container',
      locale: 'es_ES',
    };

    const _this = this;

    const dropinContainer = document.querySelector('#dropin-container');

    if (dropinContainer?.childNodes.length === 0) {
      dropin.create(braintreeData, function (err, dropinInstance) {
        if (err) {
          console.error(err);
          return;
        }
        submitButton.addEventListener('click', function () {
          _this.ngxUiLoaderService.start();
          dropinInstance.requestPaymentMethod(function (err, payload) {
            if (err) {
              _this.ngxUiLoaderService.stop();
              return;
            } else {
              _this.actualizaLicencia(payload.nonce);
            }
          });
        });
      });
    }
  }

  public obtenerInformacionMetodoDePago(): void {

    zip(
      this.getInformacionLicenciaService.__invoke(),
      this.obtenerTokenClienteBraintreeService.__invoke()
    ).subscribe({
      next: ([informacionLicencia, token]) => {
        this.informacionLicencia = informacionLicencia;

        if ((token.token.length > 0) && informacionLicencia.tipo_licencia !== 2) {
          this.createBraintreeUI(token.token);
        }
      }, error: (errorResponse) => {
        this.toastrService.error('Error al obtener la información de facturación', 'Error');
      }
    });
  }

  public enviarPago() {
    let el: HTMLElement = this.submitButton.nativeElement;
    el.click();
  }

  private actualizaLicencia(nonce: any): void {
    zip(
      this.actualizarBraintreeTokenService.__invoke(this.informacionLicencia.id),
    ).subscribe({
      next: () => {
        this.ngxUiLoaderService.stop();
        this.toastrService.success('Se han guardado los cambios con éxito', 'Información de facturacion actualizada');
        this.licenciaActualizada.emit(1);
      }, error: (errorResponse) => {
        this.ngxUiLoaderService.stop();
        this.toastrService.error('Error al actualizar la información de facturación', 'Error');
      }
    });
  }
}
