<div *ngIf="licenciaCaducada" class="alert alert-danger text-center mt-1">
  <span>{{mensajeLicencia}}</span>
  <button class="btn btn-renovar btn-sm ms-2" *ngIf="esLicenciaPagableConBraintree(licencia)"
          (click)="renovarLicencia()">
    {{'Renovar licencia' | translate}}
  </button>
</div>

<div *ngIf="!licenciaCaducada && esLicenciaPrueba(licencia)" class="alert alert-info text-center mt-1">
  <span>{{mensajeLicencia}}</span>
  <button class="btn btn-renovar btn-sm ms-2" *ngIf="esLicenciaPagableConBraintree(licencia)"
          (click)="renovarLicencia()">
    {{'Actualizar licencia' | translate}}
  </button>
</div>

