import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {MenuItem} from './menu-item.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppResolverService} from '../../../../app-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuUrl = '';

  constructor(private localStorageService: LocalStorageService,
              private httpClient: HttpClient,
              private appSettings: AppResolverService) {
    this.menuUrl = this.appSettings.getStartUpData().apiUrl +  '/backoffice-shared/menu';

  }

  public getMenu(userRole: any): Observable<MenuItem[]> {
    return this.httpClient.get(this.menuUrl, {params: {role: userRole}}).pipe(map(menu => menu as MenuItem[]));
  }
}
