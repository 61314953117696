import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormularioInformacionFacturacionComponent} from "./formulario-informacion-facturacion.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    FormularioInformacionFacturacionComponent
  ],
  exports: [
    FormularioInformacionFacturacionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class FormularioInformacionFacturacionModule {
}
