import {Component, ElementRef, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../application/authentication/authentication.service';
import {
  ObtenerInformacionTrabajadorCabeceraService
} from '../../../../../application/shared/services/obtener-informacion-cabecera/obtener-informacion-trabajador-cabecera.service';
import {InformacionTrabajadorCabecera} from '../../../../../application/shared/interfaces/models/informacion-trabajador-cabecera';
import {UsuarioRegistrado} from '../../../../../application/shared/interfaces/models/usuario-registado.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass']
})
export class UserInfoComponent implements OnInit {
  public datosCabecera = {} as InformacionTrabajadorCabecera;
  public hideUserInfo = true;
  public username = '';

  constructor(private elementRef: ElementRef,
              private authenticationService: AuthenticationService,
              private obtenerInformacionTrabajadorCabeceraService: ObtenerInformacionTrabajadorCabeceraService ) {
  }

  public ngOnInit(): void {
    this.obtenerInformacionTrabajadorCabeceraService.__invoke().subscribe({
      next: (informacionTrabajadorCabecera) => {
        this.datosCabecera = informacionTrabajadorCabecera;
      }
    });

    if (sessionStorage.getItem('suplantando_identidad')) {
      this.username = (JSON.parse(sessionStorage.getItem('usuario')) as UsuarioRegistrado).username;
    } else {
      this.username = (JSON.parse(localStorage.getItem('usuario')) as UsuarioRegistrado).username;
    }
  }

  public datosUsuarioVisibles(): void {
    this.hideUserInfo = !this.hideUserInfo;
  }

  public cerrarSesion(): void {
    this.authenticationService.logOut();
  }
}
