import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HeaderLayoutComponent} from '../../infraestructure/layout/header-layout/header-layout.component';
import {AuthGuard} from '../../application/authentication/auth-guard.service';
import {CompruebaRolGuard} from '../../infraestructure/guards/comprueba-rol.guard';
import {CompruebaEstadoOnboardingGuard} from '../../infraestructure/guards/comprueba-estado-onboarding.guard';

const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    canActivateChild: [CompruebaRolGuard, CompruebaEstadoOnboardingGuard],
    children: [
      {
        path: 'reporte-fichajes',
        loadChildren: () => import('./modules/reporte-fichajes/reporte-fichajes.module').then(m => m.ReporteFichajesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-fuera-horario',
        loadChildren: () => import('./modules/reporte-fuera-horario/reporte-fuera-horario.module').then(m => m.ReporteFueraHorarioModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-ubicacion-fichajes',
        loadChildren: () => import('./modules/reporte-ubicacion-fichajes/reporte-ubicacion-fichajes.module').then(m => m.ReporteUbicacionFichajesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'categorias-estancia',
        loadChildren: () => import('./modules/categorias-estancia/categorias-estancia.module').then(m => m.CategoriasEstanciaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'entidades-legales',
        loadChildren: () => import('./modules/entidades-legales/entidades-legales.module').then(m => m.EntidadesLegalesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-ausencias',
        loadChildren: () => import('./modules/reporte-vacaciones/reporte-vacaciones.module').then(m => m.ReporteVacacionesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'informacion-licencia',
        loadChildren: () => import('./modules/informacion-licencia/informacion-licencia.module').then(m => m.InformacionLicenciaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-vacaciones-trabajadores',
        loadChildren: () => import('./modules/reporte-vacaciones-trabajadores/reporte-vacaciones-trabajadores.module')
          .then(m => m.ReporteVacacionesTrabajadoresModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-saldos',
        loadChildren: () => import('./modules/reporte-saldos/reporte-saldos.module').then(m => m.ReporteSaldosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'informacion-facturacion',
        loadChildren: () => import('./modules/informacion-facturacion/informacion-facturacion.module').then(m => m.InformacionFacturacionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'historico-pagos',
        loadChildren: () => import('./modules/historico-pagos/historico-pagos.module').then(m => m.HistoricoPagosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'plantillas-horarios',
        loadChildren: () => import('./modules/plantillas-horarios/plantillas-horarios.module').then(m => m.PlantillasHorariosModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AdministradorRoutingModule {
}
