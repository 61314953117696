import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActualizarBraintreeTokenService {

  private braintreeUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.braintreeUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-administrador/braintree/token';
  }

  public __invoke(licenciaEmpresaId: string): Observable<any> {
    return this.httpClient.post(this.braintreeUrl + '/' + licenciaEmpresaId, {});
  }
}
