import {NgModule} from '@angular/core';
import {HeaderLayoutComponent} from '../../infraestructure/layout/header-layout/header-layout.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../application/authentication/auth-guard.service';
import {CompruebaRolGuard} from '../../infraestructure/guards/comprueba-rol.guard';

const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    canActivateChild: [CompruebaRolGuard],
    children: [
      {
        path: 'empresas',
        loadChildren: () => import('./modules/empresa/empresa.module').then(m => m.EmpresaModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SuperAdministradorRoutingModule {
}
