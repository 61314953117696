import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompruebaRolGuard  {

  constructor(private localStorageService: LocalStorageService,
              private router: Router) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const rol = this.localStorageService.getCurrentUserRole();
    if (rol === 1 && state.url.search('/empresas') === -1) {
      this.router.navigate(['/empresas']);
      return false;
    } else if (rol !== 1 && state.url === '/empresas') {
      this.router.navigate(['/dashboard']);
    }

    return true;
  }

}
