import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GetInformacionLicenciaComponent} from './get-informacion-licencia.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalRenovarLicenciaModule} from '../../../adminstrador/modules/modal-renovar-licencia/modal-renovar-licencia.module';

@NgModule({
  declarations: [
    GetInformacionLicenciaComponent
  ],
  exports: [
    GetInformacionLicenciaComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    NgbModalModule,
    ModalRenovarLicenciaModule
  ]
})
export class GetInformacionLicenciaModule {
}
