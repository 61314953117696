import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WidgetFichajeService {

  public fichajeUrl = '';

  constructor(private httpClient: HttpClient) {
    this.fichajeUrl = environment.apiUrl;
  }

  public crearRegistroEntradaSalida(data: any, token: string): Observable<any> {
    return this.httpClient.post(this.fichajeUrl + '/backoffice-trabajador/registro-acceso', data, {headers: this.getHeaders(token)});
  }

  public getUltimoEstado(token: string) {
    return this.httpClient.get( this.fichajeUrl + '/backoffice-trabajador/registro-acceso/ultimo-acceso', {headers: this.getHeaders(token)})
      .pipe(map( estado => estado as any));
  }

  public getDuracionEstanciaTrabajador(token: string): Observable<any> {
    return this.httpClient.get(this.fichajeUrl + '/backoffice-trabajador/estancia/duracion', {headers: this.getHeaders(token)})
      .pipe(map(estancias => estancias as any[]));
  }

  public getConfiguracionApp(token: string): Observable<any> {
    return this.httpClient.get(this.fichajeUrl + '/backoffice-shared/configuracion-app', {headers: this.getHeaders(token)})
      .pipe(map(configuracionApp => configuracionApp as any));
  }

  private getHeaders(token: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: 'Bearer ' + token
    });
  }
}
