import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  InformacionFacturacionFG
} from '../../../../domain/administrador/modules/informacion-facturacion/informacion-facturacion.component';
import {InformacionFacturacion} from '../../../super-administrador/interfaces/informacion-facturacion';
import {
  ObtenerInformacionFacturacionEmpresaService
} from '../../../shared/services/obtener-informacion-facturacion-empresa/obtener-informacion-facturacion-empresa.service';

@Component({
  selector: 'app-formulario-informacion-facturacion',
  templateUrl: './formulario-informacion-facturacion.component.html',
  styleUrls: ['./formulario-informacion-facturacion.component.scss']
})
export class FormularioInformacionFacturacionComponent implements OnInit {
  public informacionFacturacionForm = {} as FormGroup;
  public informacionFacturacion = {} as InformacionFacturacion;
  @Input() mostrarErroresCabecera: boolean = false;
  @Input() erroresCabecera: string[] = [];

  constructor(private formBuilder: FormBuilder,
              private obtenerInformacionFacturacionEmpresaService: ObtenerInformacionFacturacionEmpresaService,
  ) {
    this.buildForm();

  }

  ngOnInit(): void {
    this.obtenerInformacionFacturacionEmpresaService.__invoke().subscribe(
      (informacionFacturacion: InformacionFacturacion) => {
        this.informacionFacturacion = informacionFacturacion;
        this.fillForm();
      }
    );
  }

  private buildForm() {
    this.informacionFacturacionForm = this.formBuilder.group<InformacionFacturacionFG>({
      empresa_id: undefined, informacion_facturacion_id: undefined,
      informacion_facturacion_apellidos: undefined,
      informacion_facturacion_nombre_empresa: undefined,
      informacion_facturacion_cif: undefined,
      informacion_facturacion_ciudad: undefined,
      informacion_facturacion_codigo_postal: undefined,
      informacion_facturacion_direccion: undefined,
      informacion_facturacion_email: undefined,
      informacion_facturacion_nombre: undefined,
      informacion_facturacion_pais: new FormControl<string>({value: 'España', disabled: true}),
      informacion_facturacion_region: undefined,
      informacion_facturacion_telefono: undefined
    });
  }


  private fillForm(): void {
    this.informacionFacturacionForm.patchValue({
      informacion_facturacion_apellidos: this.informacionFacturacion.apellidos,
      informacion_facturacion_cif: this.informacionFacturacion.cif,
      informacion_facturacion_nombre_empresa: this.informacionFacturacion.nombre_empresa,
      informacion_facturacion_ciudad: this.informacionFacturacion.ciudad,
      informacion_facturacion_codigo_postal: this.informacionFacturacion.codigo_postal,
      informacion_facturacion_direccion: this.informacionFacturacion.direccion,
      informacion_facturacion_email: this.informacionFacturacion.email,
      informacion_facturacion_nombre: this.informacionFacturacion.nombre,
      informacion_facturacion_pais: this.informacionFacturacion.pais ?? 'España',
      informacion_facturacion_region: this.informacionFacturacion.region,
      informacion_facturacion_telefono: this.informacionFacturacion.telefono,
      empresa_id: this.informacionFacturacion.empresa_id,
      informacion_facturacion_id: this.informacionFacturacion.id
    });
  }
}
