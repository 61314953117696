import {Injectable} from '@angular/core';
import {LocalStorageService} from '../local-storage/local-storage.service';
import Pusher from 'pusher-js';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EchoService {

  public headers;
  private pusher: Pusher;

  constructor(private localStorageService: LocalStorageService) {
  }

  public getEcho(): Pusher {

    const token = this.localStorageService.getToken();

    if (!this.pusher) {

      this.pusher = new Pusher(environment.appkey, {
        wsHost: environment.websockets,
        disableStats: true,
        wsPath: environment.websocketsPath,
        forceTLS: environment.encrypted,
        cluster: 'eu',
        wsPort: 6001,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: environment.authUrl + '/pusher-login',
        authTransport: 'ajax',
        auth: {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {}
        }
      });
    }
    return this.pusher;
  }
}
