import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SoporteComponent} from './soporte.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../../../../infraestructure/directives/directives.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [SoporteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectivesModule,
    TranslateModule,
  ],
  exports: [SoporteComponent]
})
export class SoporteModule {
}
