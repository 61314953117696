import {Component, OnInit, ViewChild} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {
  GetInformacionLicenciaComponent
} from '../../../application/shared/modules/get-informacion-licencia/get-informacion-licencia.component';
import {RutaAyuda} from "./ruta-ayuda.model";
import {ObtenerAyudaService} from "../../services/obtener-ayuda.service";
import {PreviewSoporteComponent} from "../../../application/shared/modules/preview-soporte/preview-soporte.component";

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})

export class HeaderLayoutComponent implements OnInit {
  public cuentaSuplantada = false;
  @ViewChild(PreviewSoporteComponent) previewSoporte: PreviewSoporteComponent;
  @ViewChild('informacionLicencia', {static: true}) getInformacionLicenciaComponent: GetInformacionLicenciaComponent;
  public licenciaVencida = false;
  public rutaActual: string = '';
  protected rutaWordpress: string | undefined;
  private rutasWordpress = [] as RutaAyuda[];

  constructor(private meta: Meta,
              private router: Router,
              private obtenerAyudaService: ObtenerAyudaService) {
  }

  public ngOnInit(): void {
    this.rutaActual = this.router.url;
    this.obtenerRutasAyuda();
    this.getInformacionLicenciaComponent.licenciaVencida.subscribe({
      next: (licenciaVencida) => {
        this.licenciaVencida = licenciaVencida;
      }
    });
    this.cuentaSuplantada = JSON.parse(sessionStorage.getItem('suplantando_identidad'));
    this.meta.addTag({name: 'viewport', content: 'width=1024'});
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.previewSoporte) {
          this.previewSoporte.cierraPanel('soporte');
        }
        this.rutaActual = this.router.url;
        this.obtenerRutasAyuda();
      }
    });
  }


  public volverCuentaSuperAdmin(): void {
    sessionStorage.clear();
    this.router.navigate(['/empresas']);
  }

  private obtenerRutasAyuda() {
    this.obtenerAyudaService.__invoke().subscribe({
      next: (rutas) => {
        this.rutasWordpress = rutas;
        const ruta = `/${this.rutaActual.split('/')[1]}`;
        this.rutaWordpress = this.rutasWordpress.find(r => {
          if (Array.isArray(r.rutaAngular)) {
            return r.rutaAngular.includes(ruta);
          } else {
            return r.rutaAngular === ruta;
          }
        })?.rutaWordpress;
      }
    });
  }

  public abrirAyuda(): void {
    const preview = this.previewSoporte.open('soporte');
    preview.then(() => {
    }, reason => {});
  }
}


