import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObtenerNumeroDocumentosSinLeerUsuarioService {

  private documentoAdjuntoUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.documentoAdjuntoUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared/documento-adjunto/documentos-sin-leer';
  }

  public invoke(): Observable<number> {
    return this.httpClient.get(this.documentoAdjuntoUrl)
      .pipe(map(numeroDocumentos => numeroDocumentos as number));
  }
}
