import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class DescargarReporteService {
  private descargarReporteUrl: string;

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.descargarReporteUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-administrador/descargar-reporte';
  }

  public __invoke(reporte: string) {
    return this.httpClient.get(this.descargarReporteUrl + '/' + reporte, {responseType: 'blob'});
  }
}
