import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitudAyudaSoporteService {
  public soporteUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.soporteUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared';
  }

  public __invoke(data: any) {
    return this.httpClient.post(this.soporteUrl + '/ayuda', data);
  }
}
