import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObtenerTokenClienteBraintreeService {

  private braintreeUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.braintreeUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-administrador/braintree/customer/token';
  }

  public __invoke(): Observable<any> {
    return this.httpClient.get(this.braintreeUrl).pipe(map(token => token as any));
  }
}
