<nav class=" header d-flex justify-content-end header-h align-items-center">
  <button aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
          class="navbar-toggler border-0" data-target="#navbarNav" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="mr-auto"></div>

  <app-user-info></app-user-info>
</nav>

