import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  PantallaCompletaRegistroComponent
} from '../../infraestructure/layout/pantalla-completa-registro/pantalla-completa-registro.component';

const routes: Routes = [
  {
    path: '',
    component: PantallaCompletaRegistroComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/wizard-registro/wizard-registro.module').then(m => m.WizardRegistroModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {
}
