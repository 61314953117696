import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../app-resolver.service';
import {Observable} from 'rxjs';
import {InformacionTrabajador} from './trabajador.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorService {

  private trabajdorUrl = '';

  constructor(private httpClient: HttpClient,
              private appSettings: AppResolverService) {
    this.trabajdorUrl = this.appSettings.getStartUpData().apiUrlTrabajador + '/trabajador';
  }

  public getInformacionTrabajador(): Observable<InformacionTrabajador> {
    return this.httpClient.get(this.trabajdorUrl + '/informacion-trabajador')
        .pipe(map( informacionTrabajador => informacionTrabajador as InformacionTrabajador));
  }
}
