<div class="d-flex align-items-center">
  <div (click)="datosUsuarioVisibles()" class="navbar-text user-dropdown c-pointer">
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-1">
        <div class="me-1" *ngIf="datosCabecera.imagen_perfil">
          <img src="{{datosCabecera.imagen_perfil}}"
               alt="imagen perfil"
               class="avatar rounded-circle img-fluid">
        </div>
        <div class="avatar avatar-sm me-3" *ngIf="!datosCabecera.imagen_perfil">
          <div class="avatar-name rounded-circle bg-light">
            <span class="text-dark">{{datosCabecera.iniciales}}</span>
          </div>
        </div>
      </div>
      <div class="me-5 d-flex align-items-center fw-bold mt-1">
        <span class="text-white">{{'Hola, '| translate }}{{datosCabecera.nombre}}</span>
      </div>
    </div>
  </div>

  <div [ngClass]="{'d-none': hideUserInfo}" class="card position-absolute user-info pb-2 rounded"
       style="box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);">
    <div class="card-body">
      <div class="text-center h-100 my-1">
        <span class="semibold">{{username}}</span>
      </div>
      <button (click)="cerrarSesion()" class="text-center btn bg-danger text-white btn-lg w-100 semibold">
        <i class="icon-Cerrar-sesion me-1"></i> {{'Cerrar sesión' | translate}}
      </button>
    </div>
  </div>
</div>

