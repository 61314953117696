import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pantalla-completa-registro',
  templateUrl: './pantalla-completa-registro.component.html',
  styleUrls: ['./pantalla-completa-registro.component.scss']
})
export class PantallaCompletaRegistroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
