<div class="modal-header bg-grey shadow-sm">
  <h3 class="modal-title">{{'Renovar licencia'}}</h3>
  <button (click)="dismiss()" aria-label="Close" class="btn-close" data-dismiss="modal" type="button">

  </button>
</div>
<div class="modal-body p-3">
  <ng-container *ngIf="primeraParte">
    <h1>{{'Licencia básica'}}</h1>
    <p class="lead">{{'Accede con una única licencia a todas las funcionalidades sin limitaciones'}}</p>
    <h2 class="titulo-precio m-0 mb-1">2€<small class="text-body-secondary fw-light">/mes</small></h2>
    <div class="text-muted">{{'Precio por trabajador activo al mes'}}</div>
    <hr>
    <ul class="list-unstyled mt-3 mb-4 lead">
      <li class="mb-1"><strong>{{'Control horario' | translate}}</strong></li>
      <li class="mb-1"><strong>{{'Gestión de vacaciones y ausencias' | translate}}</strong></li>
      <li class="mb-1"><strong>{{'Documentos compartidos' | translate}}</strong></li>
      <li class="mb-1"><strong>{{'Firma digital de fichajes y vacaciones' | translate}}</strong></li>
      <li class="mb-1">{{'Acceso via web y dispositivos iOS y Android' | translate}}</li>
      <li class="mb-1">{{'15 GB de almacenamiento' | translate}}</li>
      <li class="mb-1">{{'Soporte vía email' | translate}}</li>
      <li class="mb-1">{{'Acceso centro de ayuda' | translate}}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="segundaParte">
    <h1>{{'Información de facturación'}}</h1>
    <p
      class="lead">{{'Estos datos serán utilizados únicamente con fines de facturación y cumplimiento de obligaciones legales.'}}</p>
    <hr>
    <app-formulario-informacion-facturacion #formularioInformacionFacturacion
                                            [mostrarErroresCabecera]="true"
    ></app-formulario-informacion-facturacion>
  </ng-container>

  <ng-container *ngIf="terceraParte">
    <h1>{{'Método de pago'}}</h1>
    <p class="lead">{{'Selecciona el método de pago que prefieras. Se cobrará en función de los usuarios activos.'}}</p>
    <hr>
    <ul class="list-group mb-3">
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Licencia básica'}}</h3>
          <small
            class="text-body-secondary">{{informacionLicencia.numero_usuarios_asignados}} {{'trabajadores activos'}}</small>
        </div>
        <span class="text-body-secondary">{{importeBruto | currency :'EUR':true}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Impuestos'}}</h3>
          <small class="text-body-secondary">{{'I.V.A.'}}</small>
        </div>
        <span
          class="text-body-secondary">{{informacionLicencia.numero_usuarios_asignados * 2 * 0.21 | currency :'EUR':true}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <div class="py-1">
          <span>Total (EUR)</span>
        </div>
        <strong>{{importeTotalPagar | currency :'EUR':true}}</strong>
      </li>
    </ul>
    <app-metodo-de-pago #metodoDePago (licenciaActualizada)="pagoRealizado()"
                        [mostrarBotonActualizar]="false"></app-metodo-de-pago>
  </ng-container>
  <ng-container *ngIf="cuartaParte">
    <h1>{{'Resumen de tu licencia'}}</h1>
    <p class="lead">{{'A continuación te mostramos un resumen de tu licencia.'}}</p>
    <hr>

    <ul class="list-group mb-3">
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Fecha de próxima renovación'}}</h3>
          <small
            class="text-body-secondary">{{'A partir de ahora los cobros se realizarán automáticamente' | translate}}</small>
        </div>
        <span
          class="text-body-secondary">{{informacionLicencia.fecha_vencimiento_licencia | date:'dd/MM/yyyy'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Número de trabajadores activos'}}</h3>
        </div>
        <span class="text-body-secondary">{{informacionLicencia.numero_usuarios_asignados}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Precio por trabajador activo al mes'}}</h3>
        </div>
        <span
          class="text-body-secondary">{{importeTotalPagar / informacionLicencia.numero_usuarios_asignados | currency :'EUR':true}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{'Precio total al mes'}}</h3>
        </div>
        <span class="text-body-secondary">{{ importeTotalPagar | currency :'EUR':true}}</span>
      </li>
    </ul>
  </ng-container>
</div>
<div class="modal-footer">
  <button (click)="navegaFacturacion()" *ngIf="primeraParte" class="w-100 btn btn-lg btn-primary"
          type="button">{{'Contratar' | translate}}</button>
  <button (click)="navegaTarjeta()" *ngIf="segundaParte" class="w-100 btn btn-lg btn-primary"
          type="button">{{'Establecer método de pago' | translate}}</button>
  <button (click)="volverPrimeraParte()" *ngIf="segundaParte" class="w-100 btn btn-lg btn-link text-primary"
          type="button">{{'Volver' | translate}}</button>
  <button (click)="volverPrimeraSegundaParte()" *ngIf="terceraParte" class="w-100 btn btn-lg btn-link text-primary"
          type="button">{{'Volver' | translate}}</button>
  <button (click)="pagar()" *ngIf="terceraParte" class="w-100 btn btn-lg btn-primary"
          type="button">{{'Renovar licencia' | translate}}</button>
  <button (click)="finalizar()" *ngIf="cuartaParte" class="w-100 btn btn-lg btn-primary"
          type="button">{{'Finalizar' | translate}}</button>
</div>


