import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InformacionTrabajadorCabecera} from '../../interfaces/models/informacion-trabajador-cabecera';

@Injectable({
  providedIn: 'root'
})
export class ObtenerInformacionTrabajadorCabeceraService {

  public readonly trabajadorUrl: string;

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.trabajadorUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared/informacion-cabecera-trabajador';
  }

  public __invoke(): Observable<InformacionTrabajadorCabecera> {
    return this.httpClient.get<InformacionTrabajadorCabecera>(this.trabajadorUrl);
  }
}
