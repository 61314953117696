import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StartupData} from './startup-data.model';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppResolverService {
  private startupData: StartupData;

  constructor() {
  }

  public load(): Promise<any> {
    this.startupData = environment;
    return Promise.resolve();
  }

  public getStartUpData(): StartupData {
    return this.startupData;
  }

}
