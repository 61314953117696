import {Component, Injector, Input, OnInit} from '@angular/core';
import {PreviewComponent} from "../../../preview/preview.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-preview-soporte',
  templateUrl: './preview-soporte.component.html',
  styleUrls: ['./preview-soporte.component.scss']
})
export class PreviewSoporteComponent extends PreviewComponent {

  protected _rutaWordpress: string;
  protected formularioSoporteVisible: boolean = false;
  @Input()
  public set rutaWordpress(value: string) {
    this._rutaWordpress = value;
  }

  constructor(private injector: Injector,
              private sanitizer: DomSanitizer) {
    super(injector);
  }

  public sanitizeUrl(_rutaWordpress: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(_rutaWordpress);
  }

  public toggleFormularioSoporte(): void {
    this.formularioSoporteVisible = !this.formularioSoporteVisible;
  }
}
