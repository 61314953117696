import { Injectable } from '@angular/core';
import {RutaAyuda} from "../layout/header-layout/ruta-ayuda.model";
import {Observable} from "rxjs";
import {AppResolverService} from "../../app-resolver.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ObtenerAyudaService {
  private readonly sharedUrl: string;

  constructor(private httpclient: HttpClient,
              private appResolverService: AppResolverService) {
    this.sharedUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared/ayuda-info';
  }

  public __invoke(): Observable<RutaAyuda[]> {
    return this.httpclient.get<RutaAyuda[]>(this.sharedUrl);
  }
}
