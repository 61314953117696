import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  InformacionFacturacion
} from '../../../super-administrador/interfaces/informacion-facturacion';

@Injectable({
  providedIn: 'root'
})
export class ObtenerInformacionFacturacionEmpresaService {

  public informacionFacturacionUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.informacionFacturacionUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared/informacion-facturacion';
  }

  public __invoke(empresaId?): Observable<InformacionFacturacion> {
    return this.httpClient.get(this.informacionFacturacionUrl + (empresaId ? '/' + empresaId : ''))
      .pipe(map(informacionFacturacion => informacionFacturacion as InformacionFacturacion));
  }
}
