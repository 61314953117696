import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {Observable} from 'rxjs';
import {InformacionFacturacion} from '../../../../application/super-administrador/interfaces/informacion-facturacion';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActualizarInformacionFacturacionService {

  public informacionFacturacionUrl = '';

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.informacionFacturacionUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-administrador/informacion-facturacion';
  }

  public __invoke(data: any): Observable<any> {
    return this.httpClient.put(this.informacionFacturacionUrl, data);
  }
}
