import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appBotonFichajeCargandoDirective]'
})
export class BotonFichajeCargandoDirective implements OnChanges {
  @Input() loading = false;

  public originalContent: string;
  public spinSpan = '<span><i class="fas fa-circle-notch fa-spin"></i></span>';

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.changeButtonStatus(changes.loading.currentValue);
  }

  public changeButtonStatus(isLoading: boolean): void {
    if (!this.originalContent) {
      this.originalContent = this.el.nativeElement.innerHTML;
    }

    if (isLoading) {
      this.showLoader();
    } else {
      this.hideLoader();
    }
  }

  private showLoader(): void {
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.spinSpan);
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.addClass(this.el.nativeElement, 'disabled');
  }

  private hideLoader(): void {
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.originalContent);
    this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    this.renderer.removeClass(this.el.nativeElement, 'disabled');
  }
}
