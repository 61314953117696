import {Injectable} from '@angular/core';
import {Configuracion} from '../../domain/configuracion/configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private currentStorage: Storage;

  constructor() {
  }

  private obtenerStorageActual() {
    if (sessionStorage.getItem('suplantando_identidad')) {
      this.currentStorage = sessionStorage;
    } else {
      this.currentStorage = localStorage;
    }
  }

  public getToken(): string {
    this.obtenerStorageActual();
    return this.currentStorage.getItem('token');
  }

  public getAuthenticatedUser() {
    this.obtenerStorageActual();
    return JSON.parse(this.currentStorage.getItem('usuario'));
  }

  public setEmpresaId(empresaId: any) {
    this.obtenerStorageActual();
    this.currentStorage.setItem('empresa_id', empresaId);
  }

  public getEmpresaId() {
    this.obtenerStorageActual();
    return JSON.parse(this.currentStorage.getItem('empresa_id'));
  }

  public getTrabajadorId() {
    this.obtenerStorageActual();
    return JSON.parse(this.currentStorage.getItem('trabajador_id'));
  }

  public getConfiguracionApp() {
    this.obtenerStorageActual();
    return JSON.parse(this.currentStorage.getItem('visibilidad_trabajador')) as Configuracion;
  }

  public getCurrentUserRole(): number {
    this.obtenerStorageActual();
    return JSON.parse(this.currentStorage.getItem('usuario'))?.role as number;
  }

  public setEstadoResumenForm(value: any): void {
    this.obtenerStorageActual();
    this.currentStorage.setItem('estado_resumen_form', value);
  }

  public getEstadoResumenForm() {
    this.obtenerStorageActual();
    return this.currentStorage.getItem('estado_resumen_form');
  }

  public clear(): void {
    this.obtenerStorageActual();
    this.currentStorage.clear();
  }
}
