<div class="menu border-dark position-fixed" style="overflow-y: auto;">
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex justify-content-center py-3 border-bottom">
      <img src="assets/img/logo-lila-tyver.svg" alt="logo lila">
    </div>
    <ul class="mt-3 ps-0">
      <ng-container *ngFor="let menuItem of menuItems; let last = last;">
        <li (click)="mostrarMenu(menuItem)" *ngIf="showElementoMenu(menuItem) && !menuItem.lastItem"
            [ngClass]="{
            'active bg-menu-item-activo': isActivo(menuItem),
            'borde-item-activo': isActivo(menuItem) && (!last || menuItems.length === 1) || !menuItem.lastItem && last && isActivo(menuItem),
            'borde-last-item-activo':  isActivo(menuItem) && last && menuItem.lastItem && (menuItems.length > 1)
          }"
            [routerLink]="menuItem.url"
            class="px-3 text-center py-3 c-pointer">
          <div class="d-flex align-items-center ms-4">
            <div [ngClass]="{'menu-icon': !isActivo(menuItem), 'menu-icon-activo': isActivo(menuItem)}">
          <span class="nuevo-badge-menu" [hidden]="!menuItem.badgeCount" id="{{menuItem.title}}">
            <i class="{{menuItem.iconClass}} nuevo-badge-stack-1x"></i>
          </span>
              <i class="{{menuItem.iconClass}}" [hidden]="menuItem.badgeCount"></i>
            </div>
            <div class="ms-3"
                 [ngClass]="{'menu-title': !isActivo(menuItem), 'menu-title-activo': isActivo(menuItem)}">
              <span>{{ menuItem.title }}</span>
            </div>
          </div>
        </li>
        <div *ngIf="last" class="border-bottom mt-3"></div>
      </ng-container>
    </ul>
    <div class="border-top">
      <ul class="ps-0 mb-0">
        <ng-container *ngFor="let menuItem of menuItems; let last = last;">
          <li (click)="mostrarMenu(menuItem)" *ngIf="showElementoMenu(menuItem) && menuItem.lastItem"
              [ngClass]="{
            'active bg-menu-item-activo': isActivo(menuItem),
            'borde-item-activo': isActivo(menuItem)
          }"
              [routerLink]="menuItem.url"
              class="px-3 text-center py-3 c-pointer">
            <div class="d-flex align-items-center ms-4">
              <div [ngClass]="{'menu-icon': !isActivo(menuItem), 'menu-icon-activo': isActivo(menuItem)}">
          <span class="nuevo-badge-menu" [hidden]="!menuItem.badgeCount" id="{{menuItem.title}}">
            <i class="{{menuItem.iconClass}} nuevo-badge-stack-1x"></i>
          </span>
                <i class="{{menuItem.iconClass}}" [hidden]="menuItem.badgeCount"></i>
              </div>
              <div class="ms-3"
                   [ngClass]="{'menu-title': !isActivo(menuItem), 'menu-title-activo': isActivo(menuItem)}">
                <span>{{ menuItem.title }}</span>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<div [style.transform]="menuVisible ? 'translateX(237px)' : 'translateX(-100%)'"
     class="h-100 menu-wrap border border-right py-2 text-white" style="overflow-y: auto;">
  <div *ngIf="menuItem" class="menu-body">
    <ul class="ps-0 mt-3">
      <ng-container *ngFor="let child of menuItem.childItems">
        <li class="px-3 text-center py-2 c-pointer"
            [ngClass]="{
             'menu-title child-link': !(currentRoute.search(child.url) !== -1),
             'menu-title-activo text-white bg-menu-subitem-activo': currentRoute.search(child.url) !== -1
              }">
          <div class="d-flex align-items-center ms-4">
            <span (click)="navegar(child.url)">
              <i class="{{child.icon}} submenu-icon"></i>
              <span class="ms-3">{{ child.title }}</span>
            </span>
          </div>
        </li>
      </ng-container>
    </ul>

  </div>
</div>
<div (click)="ocultarMenu()" *ngIf="menuVisible" class="w-100 click-outside-menu bg-dark"></div>
