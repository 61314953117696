import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SolicitudAyudaSoporteService} from './solicitud-ayuda-soporte.service';
import {ValidationService} from '../../../../infraestructure/validation/validation.service';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.sass']
})
export class SoporteComponent implements OnInit {
  public soporteForm: UntypedFormGroup;
  public enviando = false;
  @Output() cerrar = new EventEmitter<boolean>();

  constructor(private formBuilder: UntypedFormBuilder,
              private solicitudAyudaSoporteService: SolicitudAyudaSoporteService,
              private toastrService: ToastrService,
              private validationService: ValidationService) {
  }

  public ngOnInit() {
    this.buildForm();
  }

  public dismiss() {
    this.cerrar.emit(false);
  }

  private buildForm() {
    this.soporteForm = this.formBuilder.group({
      asunto: null,
      descripcion: null,
    });
  }

  public enviarTicketSoporte() {
    this.enviando = true;
    this.solicitudAyudaSoporteService.__invoke(this.soporteForm.value).subscribe(result => {
      this.enviando = false;
      this.toastrService.success(
        'Se ha enviado un solicitud de ayuda al departamento correspondiente, le responderán en breve.',
        'Ticket creado'
      );
      this.dismiss();
    }, errorResponse => {
      this.enviando = false;
      this.validationService.validateForm(this.soporteForm, errorResponse.error.errors);
      this.toastrService.error('No se podido crear la solicitud de ayuda. Inténtelo de nuevo más tarde.', 'Error');
    });
  }
}
