import {NgModule} from '@angular/core';
import {WidgetFichajeComponent} from './widget-fichaje.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

/*import {TranslateModule} from '@ngx-translate/core';*/


@NgModule({
  declarations: [WidgetFichajeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: WidgetFichajeComponent
      }
    ]),
    ReactiveFormsModule
  ],
  exports: [WidgetFichajeComponent],

})
export class WidgetFichajeModule { }
