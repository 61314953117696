import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppResolverService} from "../../../../app-resolver.service";
import {Observable} from "rxjs";
import {TrabajadorAusente} from "../../interfaces/models/trabajador-ausente.model";
import {map} from "rxjs/operators";
import {InformacionLicencia} from "./informacion-licencia";

@Injectable({
  providedIn: 'root'
})
export class GetInformacionLicenciaService {

  constructor(private httpClient: HttpClient,
              private appSettings: AppResolverService) {
  }

  public __invoke(empresaId?: number | null): Observable<InformacionLicencia> {
    return this.httpClient.get(this.appSettings.getStartUpData().apiUrl + '/backoffice-shared/licencia-empresa' + (empresaId ? '?empresa_id=' + empresaId : ''))
      .pipe(map(informacion => informacion as InformacionLicencia));
  }
}
