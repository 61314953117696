import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthInfo, UsuarioRegistrado} from '../shared/interfaces/models/usuario-registado.model';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SocialAuthService} from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authUrl = '';
  public redirectUrl = '';

  constructor(private router: Router,
              private httpClient: HttpClient,
              private socialAuthService: SocialAuthService) {
  }

  public login(credenciales: any): Observable<AuthInfo> {
    return this.httpClient.post(this.authUrl + '/login', credenciales)
      .pipe(map(usuario => usuario as AuthInfo));
  }

  public registrarCuenta(datosCuenta: any): Observable<UsuarioRegistrado> {
    return this.httpClient.post(this.authUrl + '/registrar-usuario', datosCuenta)
      .pipe(map(usuario => usuario as UsuarioRegistrado));
  }

  public registrarCuentaSocial(datosCuenta: any): Observable<UsuarioRegistrado> {
    return this.httpClient.post(this.authUrl + '/registrar-usuario-social', datosCuenta)
      .pipe(map(usuario => usuario as UsuarioRegistrado));
  }

  public isLogged(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  public refreshToken(): Observable<any> {
    return this.httpClient.get(this.authUrl + '/refresh');
  }

  public logOut(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.socialAuthService.signOut().catch(() => {});
    this.router.navigate(['auth/login']);

  }

  public loginSocial(loginData: any): Observable<AuthInfo> {
    return this.httpClient.post(this.authUrl + '/login-social', loginData)
      .pipe(map(usuario => usuario as AuthInfo));
  }

  public resetPassword(value: any): Observable<any> {
    return this.httpClient.post(this.authUrl + '/reset', value);
  }

  public createNewPassword(value: any): Observable<any> {
    return this.httpClient.post(this.authUrl + '/password/reset', value);
  }
}
