import {Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appDragAndDropFile]'
})
export class DragAndDropFileDirective implements OnInit {
  @Output() private filesChangeEmiter: EventEmitter<FileList> = new EventEmitter();
  private dropZone;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private modal: NgbModal) { }

  public ngOnInit(): void {
    this.getDropZone();
    window.addEventListener('dragenter', (event) => {
      this.modal.dismissAll();
      this.renderer.removeClass(this.dropZone, 'invisible');
    });

    this.dropZone.addEventListener('dragenter', (event) => {
      this.allowDrag(event);
    });

    this.dropZone.addEventListener('dragover', (event) => {
      this.allowDrag(event);
    });

    this.dropZone.addEventListener('dragleave', (event) => {
      this.renderer.addClass(this.dropZone, 'invisible');
    });

    this.dropZone.addEventListener('drop', (event) => {
      this.renderer.addClass(this.dropZone, 'invisible');
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.filesChangeEmiter.emit(files);
        this.renderer.removeClass(this.elementRef.nativeElement, 'on-drag');
      }
    });
  }

  private allowDrag(event) {
    event.preventDefault();
  }

  private getDropZone() {
    this.dropZone = this.renderer.createElement('div');
    this.renderer.addClass(this.dropZone, 'dropZone');
    this.renderer.addClass(this.dropZone, 'invisible');
    this.renderer.appendChild(this.elementRef.nativeElement, this.dropZone);
  }

}
