import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppResolverService} from '../../../../app-resolver.service';
import {EstadoOnboardingTrabajador} from '../../interfaces/models/onboarding';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObtenerEstadoOnboardingTrabajadorService {

  public readonly onboardingUrl: string;

  constructor(private httpClient: HttpClient,
              private appResolverService: AppResolverService) {
    this.onboardingUrl = this.appResolverService.getStartUpData().apiUrl + '/backoffice-shared/onboarding';
  }

  public async __invoke(): Promise<EstadoOnboardingTrabajador> {
    const observable$ = this.httpClient.get<EstadoOnboardingTrabajador>(this.onboardingUrl);
    return await firstValueFrom(observable$);
  }
}
