<div class="p-0 h-100">
  <div class="d-flex">
    <div class="justify-content-start menu h-100">
      <app-menu [esLicenciaVencida]="licenciaVencida"></app-menu>
    </div>
    <div class="fixed-top">
      <app-header></app-header>
    </div>
    <div class="flex-fill container-fluid bg-blanco main-content">
        <div class="alert alert-danger text-center my-2" *ngIf="cuentaSuplantada">
          {{'Está haciendo uso de cuenta de usuario que no es suya, para volver a su cuenta haga clic' | translate}}
          <button class="btn btn-primary btn-sm"
                  (click)="volverCuentaSuperAdmin()">{{'Volver a mi cuenta' | translate}}</button>
        </div>
        <app-get-informacion-licencia #informacionLicencia></app-get-informacion-licencia>
        <router-outlet></router-outlet>
        <button class="boton-soporte boton-flotante" (click)="abrirAyuda()" *ngIf="rutaWordpress">
          <i class="icon-soporte icon-2"></i>
        </button>
    </div>
  </div>
</div>
<app-preview-soporte  *ngIf="rutaWordpress"
                      id="soporte"
                      class="panel-wrap sm"
                      [rutaWordpress]="rutaWordpress"></app-preview-soporte>




