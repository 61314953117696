import {AfterViewInit, Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {InformacionLicencia} from './informacion-licencia';
import {GetInformacionLicenciaService} from './get-informacion-licencia.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {LocalStorageService} from '../../../../infraestructure/local-storage/local-storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ModalRenovarLicenciaComponent
} from '../../../adminstrador/modules/modal-renovar-licencia/modal-renovar-licencia.component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-get-informacion-licencia',
  templateUrl: './get-informacion-licencia.component.html',
  styleUrls: ['./get-informacion-licencia.component.scss']
})
export class GetInformacionLicenciaComponent implements AfterViewInit, OnDestroy {

  public licencia = {} as InformacionLicencia;
  private readonly rol: number;
  public licenciaCaducada = false;
  public mensajeLicencia = '';
  @Output() licenciaVencida = new EventEmitter<boolean>();

  constructor(private datePipe: DatePipe,
              private getInformacionLicencia: GetInformacionLicenciaService,
              private toastrService: ToastrService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private ngbModal: NgbModal,) {
    this.rol = this.localStorageService.getCurrentUserRole();
  }

  public ngAfterViewInit() {
    if (this.rol == 1) {
      return;
    }
    const fechaActual = new Date().toISOString().split('T')[0];

    this.getInformacionLicencia.__invoke().subscribe((licencia) => {
      this.licencia = licencia;
      this.licenciaCaducada = this.licencia.fecha_vencimiento_licencia < fechaActual;
      this.licenciaVencida.emit(this.licenciaCaducada);

      if (this.esLicenciaPrueba(licencia) && !this.licenciaCaducada) {
        this.mensajeLicencia = 'Su periodo de prueba finalizará el día ' + this.datePipe.transform(licencia.fecha_vencimiento_licencia, 'dd/MM/yyyy') + ', puede actualizar la licencia para seguir utilizando la aplicación';
        this.inicializarChat()
      } else if (this.esLicenciaPagableConBraintree(licencia) && this.licenciaCaducada) {
        this.mensajeLicencia = 'Su licencia ha caducado, actualice su licencia para seguir utilizando la aplicación';
      } else if (!this.esLicenciaPagableConBraintree(licencia) && this.licenciaCaducada) {
        this.mensajeLicencia = 'Su licencia ha caducado, para más información contacte info@lunia.es';
      }
    });
  }

  public ngOnDestroy() {
    const tidio = document.querySelector('#tidio')
    const tidio_chat = document.querySelector('#tidio-chat')
    const tidio_chat_code = document.querySelector('#tidio-chat-code')
    // must check if exists before removing
    if (tidio) {
      tidio.remove()
    }
    if (tidio_chat) {
      tidio_chat.remove()
    }
    if (tidio_chat_code) {
      tidio_chat_code.remove()
    }
  }

  public esLicenciaPagableConBraintree(licencia: InformacionLicencia): boolean {
    return (this.rol == 2) && (licencia.tipo_licencia === 1 || licencia.tipo_licencia === 3);
  }

  public esLicenciaPrueba(licencia: InformacionLicencia): boolean {
    return (this.rol != 1) && (licencia.tipo_licencia === 1);
  }

  public renovarLicencia(): void {
    const modalRef = this.ngbModal.open(ModalRenovarLicenciaComponent, {backdrop: 'static'});
    modalRef.result.then((result) => {
      window.location.reload();
    }).catch((error) => {
    });
  }

  private inicializarChat() {
    setTimeout(function () {
      var tidioScript = document.createElement('script');
      tidioScript.id = 'tidio'
      tidioScript.src = "//code.tidio.co/jyzwaermkh5vzql6ugvxnmqdwltbjorh.js";
      document.body.appendChild(tidioScript);
    }, 1500);
  }
}
