import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObtenerContrasteColorService {
  public __invoke(bgColor: string): string {
    if (!bgColor) return 'black';

    if (bgColor.length === 4) {
      bgColor = '#' + bgColor[1] + bgColor[1] + bgColor[2] + bgColor[2] + bgColor[3] + bgColor[3];
    }

    const r = parseInt(bgColor.substring(1, 2), 16);
    const g = parseInt(bgColor.substring(3, 2), 16);
    const b = parseInt(bgColor.substring(5, 2), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? 'black' : 'white';
  }
}
