import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullScreenLayoutComponent} from './infraestructure/layout/full-screen-layout/full-screen-layout.component';
import {HeaderLayoutComponent} from './infraestructure/layout/header-layout/header-layout.component';
import {AuthGuard} from './application/authentication/auth-guard.service';
import {
  ProbarWebComponentsLayoutComponent
} from './infraestructure/layout/probar-web-components-layout/probar-web-components-layout.component';
import {CompruebaRolGuard} from './infraestructure/guards/comprueba-rol.guard';
import {PantallaCompletaRegistroComponent} from './infraestructure/layout/pantalla-completa-registro/pantalla-completa-registro.component';
import {CompruebaEstadoOnboardingGuard} from './infraestructure/guards/comprueba-estado-onboarding.guard';

const loggedUser = JSON.parse(localStorage.getItem('usuario'));
const defaultNavigateRoute = loggedUser ? (loggedUser.role === 1 ? 'empresas' : 'dashboard') : 'dashboard';

const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    canActivateChild: [CompruebaRolGuard, CompruebaEstadoOnboardingGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: defaultNavigateRoute,
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./domain/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'horarios',
        loadChildren: () => import('./domain/horarios/horarios.module').then(m => m.HorariosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'trabajadores',
        loadChildren: () => import('./domain/trabajador/trabajador.module').then(m => m.TrabajadorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'equipos',
        loadChildren: () => import('./domain/equipos/equipos.module').then(m => m.EquiposModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'app-configuracion',
        loadChildren: () => import('./domain/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'empresa',
        loadChildren: () => import('./domain/datos-empresa/datos-empresa.module').then(m => m.DatosEmpresaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'entradas-salidas',
        loadChildren: () => import('./domain/entradas-salidas/entradas-salidas.module').then(m => m.EntradasSalidasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'estancias',
        loadChildren: () => import('./domain/estancias/estancias.module').then(m => m.EstanciasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'listado-ausencias',
        loadChildren: () => import('./domain/ausencias/ausencias.module').then(m => m.AusenciasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'calendario-ausencias',
        loadChildren: () => import('./domain/shared/modules/calendario-ausencias/calendario-ausencias.module').then(m => m.CalendarioAusenciasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'control-laboral',
        loadChildren: () => import('./domain/control-laboral/control-laboral.module').then(m => m.ControlLaboralModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'documentos',
        loadChildren: () => import('./domain/documentos/documentos.module').then(m => m.DocumentosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'documentos-trabajador',
        loadChildren: () => import('./domain/documentos-trabajador/documentos-trabajador.module').then(m => m.DocumentosTrabajadorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'configuracion-usuario',
        loadChildren: () => import('./domain/configuracion-usuario/configuracion-usuario.module').then(m => m.ConfiguracionUsuarioModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'fichajes',
        loadChildren: () => import('./domain/fichajes/fichajes.module').then(m => m.FichajesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sedes',
        loadChildren: () => import('./domain/sedes/sedes.module').then(m => m.SedesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dispositivos',
        loadChildren: () => import('./domain/dispositivos/dispositivos.module').then(m => m.DispositivosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'calendario-festivos',
        loadChildren: () => import('./domain/calendarios-festivos/calendarios-festivos.module').then(m => m.CalendariosFestivosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tipos-ausencia',
        loadChildren: () => import('./domain/tipo-ausencia/tipo-ausencia.module').then(m => m.TipoAusenciaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'politicas-vacaciones',
        loadChildren: () => import('./domain/administrador/modules/politicas-vacaciones/politicas-vacaciones.module').then(m => m.PoliticasVacacionesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jornadas',
        loadChildren: () => import('./domain/jornadas/jornadas.module').then(m => m.JornadasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'ausencias-equipo',
        loadChildren: () => import('./domain/calendario-ausencias-equipo/calendario-ausencias-equipo.module')
          .then(m => m.CalendarioAusenciasEquipoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-ausencias',
        loadChildren: () => import('./domain/ausencias-trabajador/ausencias-trabajador.module')
          .then(m => m.AusenciasTrabajadorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-fichajes',
        loadChildren: () => import('./domain/meses-fichaje/meses-fichaje.module')
          .then(m => m.MesesFichajeModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'auth',
    component: PantallaCompletaRegistroComponent,
    data: {
      title: 'login'
    },
    loadChildren: () => import('./domain/autenticacion/autenticacion.module').then(m => m.AutenticacionModule)
  },
  {
    path: 'webcomponents',
    component: ProbarWebComponentsLayoutComponent
  },
  {
    path: 'fichaje-pin',
    component: FullScreenLayoutComponent,
    loadChildren: () => import('./domain/fichaje-pin/fichaje-pin.module').then(m => m.FichajePinModule)
  },
  {
    path: 'fichaje-qr',
    component: FullScreenLayoutComponent,
    loadChildren: () => import('./domain/fichaje-qr/fichaje-qr.module').then(m => m.FichajeQrModule)
  },
  {
    path: 'onboarding',
    component: PantallaCompletaRegistroComponent,
    loadChildren: () => import('./domain/shared/modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
