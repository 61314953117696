import {inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {
  ObtenerEstadoOnboardingTrabajadorService
} from '../../application/shared/services/obtener-estado-onboarding-trabajador/obtener-estado-onboarding-trabajador.service';
import {EstadoOnboardingTrabajador} from '../../application/shared/interfaces/models/onboarding';

@Injectable({
  providedIn: 'root'
})
export class CompruebaEstadoOnboardingGuard  {

  constructor(private router: Router,
              private obtenerEstadoOnboardingTrabajadorService: ObtenerEstadoOnboardingTrabajadorService) {
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const estadoOnboarding = localStorage.getItem('onboarding_completado');
    if (estadoOnboarding === 'true') {
      return true;
    }

    let estadoOnboardingTrabajador = {} as EstadoOnboardingTrabajador;

    try {
      estadoOnboardingTrabajador = await this.obtenerEstadoOnboardingTrabajadorService.__invoke();
    } catch (e) {
      await this.router.navigate(['/auth/login']);
      return false;
    }

    if (estadoOnboardingTrabajador.completadoWeb) {
      localStorage.setItem('onboarding_completado', 'true');
      return true;
    }

    await this.router.navigate(['/onboarding']);
    return false;
  }

}
