import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appLoadingButtonDirective]'
})
export class LoadingButtonDirective implements OnInit, OnChanges {
  @Input() loading = false;
  spinSpan;
  button;
  span = '<span class="d-none"><i class="fas fa-circle-notch fa-spin"></i></span>';

  constructor(private el: ElementRef,
              private renderer: Renderer2) {

  }
  ngOnInit(): void {
    this.button = this.el.nativeElement;

    this.button.insertAdjacentHTML('beforeend', this.span);

    this.spinSpan = this.button.querySelector('span');
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (this.isViewReady()) {
      this.changeButtonStatus(changes.loading.currentValue);
    }
  }

  public changeButtonStatus(status: boolean): void {
    if (status) {
      this.renderer.removeClass(this.spinSpan, 'd-none');
      this.renderer.setAttribute(this.button, 'disabled', 'true');
      this.renderer.addClass(this.button, 'disabled');

    } else {
      this.renderer.addClass(this.spinSpan, 'd-none');
      this.renderer.removeAttribute(this.button, 'disabled');
      this.renderer.removeClass(this.button, 'disabled');
    }
  }

  isViewReady() {
    return !!(this.spinSpan && this.button);
  }
}
